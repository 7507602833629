import { useTranslation } from "react-i18next";
import Collapse from "../../../Common/Collapse/Collapse";
import "./Faq.scss";
import { FC } from "react";
import { FaqData } from "../Faq/FaqAndArticle";
import { isEnglish } from "../../../../utils/Utils";

interface Props {
  data?: FaqData[];
}

const Faq: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="tds-sn-faq-container">
      <Collapse
        title={t("learn.more.faq.title")}
        bottomBorder="none"
        displayType="flex"
        isParentSection={true}
      >
        {data?.map((faq: FaqData, index: number) => (
          <Collapse
            key={index}
            id={"" + index}
            title={isEnglish() ? faq.questionEn : faq.questionFr}
            bottomBorder="none"
            displayType="flex"
          >
            <div style={{ paddingLeft: "42px" }} tabIndex={0}>
              {isEnglish() ? faq.answerEn : faq.answerFr}
            </div>
          </Collapse>
        ))}
      </Collapse>
    </div>
  );
};
export default Faq;
