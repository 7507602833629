import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { APP_LOGOUT_URL_KEY, APP_ID_KEY, useAuth } from "../auth/AuthProvider";

interface ILogoutProps {}

const Logout: React.FC<ILogoutProps> = () => {
  let { logout, logoutApp, error } = useAuth();
  let [searchParams] = useSearchParams();

  React.useEffect(() => {
    const appLogoutUrl = searchParams.get(APP_LOGOUT_URL_KEY);
    const appId = searchParams.get(APP_ID_KEY);
    if (appId && appLogoutUrl) {
      logoutApp(appId, appLogoutUrl);
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {searchParams.get(APP_ID_KEY) && searchParams.get(APP_LOGOUT_URL_KEY) ? (
        <>{error ? <div>Error getting access token: {error}</div> : <></>}</>
      ) : (
        <>
          <h1>One Portal</h1>
          <p>You have successfully logged out of One Portal!</p>
          <p>
            <Link to="/">Home</Link>
          </p>
        </>
      )}
    </>
  );
};

export default Logout;
