import ReactGA from "react-ga4";
import { Cookies } from "react-cookie-consent";

export const getTrackerId = () => {
  let trackingId = "FG08RX0SK8"; //PROD

  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("dev.")
  ) {
    trackingId = "W9YHSG7G0B"; //DEV
  } else if (window.location.href.includes("pat.")) {
    trackingId = "Z659NFRYG4"; //PAT
  }

  return trackingId;
};

export const removeTracking = () => {
  //remove google analytics cookies
  const domain = extractDomain(window.location.hostname);
  const attributes = { path: "/", domain: domain };

  Cookies.remove("_ga_" + getTrackerId(), attributes);
  Cookies.remove("_ga", attributes);
  Cookies.remove("_gat", attributes);
  Cookies.remove("_gid", attributes);

  ReactGA.reset();
};

//returns .td.com or .tdsecurities.com or .tdbank.com or localhost
const extractDomain = (hostname: string) => {
  let domain: string = "";

  if (hostname.includes("localhost")) {
    domain = "localhost";
  } else {
    const parts: string[] = hostname.split(".");

    if (parts.length > 1) {
      domain = "." + parts[parts.length - 2] + "." + parts[parts.length - 1];
    }
  }

  return domain;
};
