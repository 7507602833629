import Registration from "../components/HomePage/RegistrationRequest/RegistrationData";
import { createSecureHttpClient } from "../utils/HttpClient";
import { AppAccessRequest } from "../components/HomePage/RegistrationRequest/AppAccessRequestUtils";

export const registerUser = async (token: string, data: Registration) => {
  return createSecureHttpClient(token).post<Registration>(
    "/api/newuserrequests",
    data
  );
};

export const registerAppAccessRequests = async (
  token: string,
  data: AppAccessRequest
) => {
  return createSecureHttpClient(token).post<AppAccessRequest>(
    "/api/app-requests",
    data
  );
};
