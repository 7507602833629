import * as React from "react";
import { ChangeEvent, FC, useState } from "react";
import { Field } from "../../../data/apiData";
import { ApplicationDto } from "./AppAccessRequestUtils";
import { useTranslation } from "react-i18next";
import { showErrorMessage } from "./AppAccessRequest";
import { MESSAGE } from "../../Common/ErrorMessage/ErrorMessagesConstants";

interface Props {
  validated: boolean;
  field: Field;
  appId: string;
  appDto: ApplicationDto;
  handleFieldValueChanged: (
    appId: string,
    fieldId: number,
    value: string | boolean | number | number[]
  ) => void;
}

const TextInputField: FC<Props> = ({
  validated,
  field,
  appId,
  appDto,
  handleFieldValueChanged,
}) => {
  const { t, i18n } = useTranslation();
  const [changed, setChanged] = useState<boolean>(false);

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const handleTextInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    handleFieldValueChanged(appId, field.id, event.target.value);
  };

  const isFieldSet = (fieldId: number) => {
    return appDto.fields.find((f) => f.id === fieldId)?.value;
  };

  return (
    <div
      key={"text_input_id_" + field.id}
      className={`tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding ${
        field.required && "tds-landing-form-required"
      }`}
    >
      <label
        htmlFor={isEnglish() ? field.displayName : field.displayNameFrench}
      >
        {isEnglish() ? field.displayName : field.displayNameFrench}
      </label>
      <div>
        <input
          name={isEnglish() ? field.displayName : field.displayNameFrench}
          type="text"
          maxLength={65}
          className="form-control"
          onBlur={() => setChanged(true)}
          id={isEnglish() ? field.displayName : field.displayNameFrench}
          onChange={handleTextInputChanged}
        />
      </div>
      {(changed || validated) &&
        field.required &&
        !isFieldSet(field.id) &&
        showErrorMessage(
          t(MESSAGE),
          isEnglish() ? field.displayName : field.displayNameFrench
        )}
    </div>
  );
};

export default TextInputField;
