import { FC, useState } from "react";
import "./Collapse.scss";

interface Props {
  isExpandable?: boolean;
  id?: string;
  title?: string;
  className?: string;
  bottomBorder?: string;
  displayType?: string;
  children?: any;
  isParentSection?: boolean;
}

const Collapse: FC<Props> = ({
  isExpandable,
  id,
  title,
  className,
  bottomBorder,
  displayType,
  children,
  isParentSection
}) => {
  const [open, setOpen] = useState(isExpandable ? true : false);

  const toggleDetails = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={`tds-sn-collapse-container ${className || null} ${
          open ? "open" : null
        }`}
      >
        <div className="summary" style={{ borderBottom: bottomBorder, borderTop: `${isParentSection ? "none" : ""}`}}>
          <button
            title={title}
            onClick={toggleDetails}
            aria-controls={id}
            aria-expanded={open}
            style={{ display: displayType }}
          >
            {open ? (
              <span
                className={`td-icon td-icon-collapse ${isParentSection ? "parent-sect-margin-top" : ""} icon-small active`}
                title="Collapse"
              />
            ) : (
              <span
                className={`td-icon td-icon-expand icon-small active ${isParentSection ? "parent-sect-margin-top" : ""}`}
                title="Expand" 
              />
            )}
            { isParentSection && <h2 style={{fontSize: `${isParentSection ? "25px" : ""}`}}>{title}</h2>}
            {!isParentSection && <div>{title}</div>}
          </button>
        </div>
        {open ? (
          <div id={id} className="details">
            {children}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Collapse;
