export const ROOT = "/";
export const LOGIN = "/login";
export const CALLBACK = "/callback";
export const AD_CHOICES = "/ad_choices";
export const LANDING_AD_CHOICES = "/landing_ad_choices";
export const LEARN = "/learn";
export const LANDING_LEARN = "/landing_learn";
export const LOGOUT = "/logout";
export const ERROR_NOT_ACTIVE = "/error/not-active";
export const ERROR_NOT_AUTHORIZED = "/error/not-authorized";
export const REGISTRATION = "/registration-request";
export const SHORT_REGISTRATION = "/register";
export const REQUEST_ACCESS_CONFIRMATION = "/request-access-confirmation";
export const UAP_REDIRECT = "/security/uap-redirect";
export const FAQS_ARTICLES = "/api/faqs-articles";
export const HELP_ARTICLES = "/api/help-articles";
export const ERROR = "/global-error";
export const LANDING_CONTACT_US = "/landing_contact-us";
export const CONTACT_US = "/contact-us";
export const CONTACT_CATEGORY = "/api/content-category";
