import { FC } from "react";
import { Link } from "react-router-dom";
import {Language, LinkType} from "../../../constants/types";
import "./MobileNavLink.scss";

export interface MobileNavLinkProps {
  link: string;
  name: string;
  type: LinkType;
  withLanguageCheckMark?: boolean;
  language?: Language;
  onLanguageChange?: (language: Language) => void;
  onInternalLinkChange?: () => void;
}

const MobileNavLink: FC<MobileNavLinkProps> = ({
  link,
  name,
  type,
  withLanguageCheckMark,
  language,
  onLanguageChange,
  onInternalLinkChange,
}) => {
  return (
    <div className="tds-mobile-navbar-item tds-mobile-navbar-main-link">
      {type === LinkType.EXTERNAL && (
        <a href={link} title={name} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      )}
      {type === LinkType.INTERNAL && (
        <Link
          to={link}
          title={name}
          onClick={() => {
            onInternalLinkChange!();
          }}
        >
          {name}
        </Link>
      )}
      {type === LinkType.LANGUAGE && (
        <>
          <a
            href={link}
            title={name}
            onClick={(e) => {
              e.preventDefault();
              onLanguageChange!(language!);
            }}
          >
            {name}
          </a>
          {withLanguageCheckMark && (
            <span className="td-icon td-icon-checkMark icon-small"></span>
          )}
        </>
      )}
    </div>
  );
};

export default MobileNavLink;
