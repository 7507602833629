export enum LinkType {
  INTERNAL,
  EXTERNAL,
  LANGUAGE,
}

export enum Language{
  en_CA="en_CA",
  fr_CA="fr_CA"
}

export enum ApplicationTransferType {
  JWT = "JWT",
  SAML = "SAML",
}

export interface CountryISOItem {
  value: string;
  label: string;
}
