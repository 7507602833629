import React, { FC } from "react";
import { CategoryContentItem } from "./CategoryContent";
import { ApplicationStatus } from "../../../data/apiData";
import { useTranslation } from "react-i18next";
import lockIcon from "../../../assets/images/lock.svg";
import "./CategoryContent.scss";

interface CategoryItemProps {
  item: CategoryContentItem;
  id: number;
  onClicked: (
    name: string,
    link: string,
    target: string,
    appStatus: ApplicationStatus,
    userHasAccess: boolean
  ) => void;
  actionText: string;
  buttonVisible: boolean;
}

const CategoryItem: FC<CategoryItemProps> = ({
  item,
  id,
  onClicked,
  actionText,
  buttonVisible,
}) => {
  const { t } = useTranslation();

  const getAriaLabelText = () => {
    if (actionText === t("home.app.type.has-access")) {
      return t("home.app.type.has-access") + t("link.open.in.new.tab.text");
    }
    if (actionText === t("home.app.type.request.in.process")) {
        return t("home.app.type.request.in.process");
      }
    if (actionText === t("home.app.type.no-access")) {
      return t("home.app.type.no-access");
    }
    if (actionText === t("home.app.type.external-login")) {
      return t("home.app.type.external-login") + t("link.open.in.new.tab.text");
    }
  };

  return (
    <React.Fragment key={`item_${id}`}>
      <div
        className="category-item-container-card tds-three-column-single-container category-item-container"
        tabIndex={0}
      >
        <div>
          <img
            src={`../../../assets/img/${item.imageUrl}`}
            alt={item.name}
            className="tds-tailored-for-you-image"
            width="115px"
            height="350px"
          />
          <div
            className="tds-three-column-single-container-content"
            title={item.description}
          >
            <h3>{item.name}</h3>
            <span className="category-item-description-box">
              {item.description}
            </span>
          </div>
          {buttonVisible && (
            <button
              type="button"
              className="btn btn-block td-btn-secondary-clear category-item-login-button"
              onClick={() =>
                onClicked(
                  item.name,
                  item.link,
                  item.target,
                  item.status,
                  item.userHasAccess
                )
              }
              disabled={item.requestInProcess}
              aria-disabled={item.requestInProcess}
              aria-label={getAriaLabelText()}
            >
            { item.requestInProcess && <img
            src={lockIcon}
            alt={t("home.app.type.request.in.process")}
            className="tds-lock-image"
          />}{actionText}
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategoryItem;
