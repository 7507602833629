import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROOT } from "../constants/routePaths";
import "./NotFound.scss";

interface INotFoundProps {}

const NotFound: React.FC<INotFoundProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHomeButtonClick = () => {
    navigate(ROOT);
  };

  return (
    <>
      <section className="tds-fourofour-page">
        <div className="tds-fourofour-page-container">
          <div className="tds-fourofour-page-row">
            <div className="tds-fourofour-page-column">
              <div className="tds-fourofour-page-column-description">
                <p>{t("404.description")}</p>
              </div>
              <div className="tds-fourofour-page-button-wrapper">
                <button
                  title={t("404.home")}
                  className="btn td-btn-primary-light"
                  onClick={onHomeButtonClick}
                >
                  {t("404.home")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
