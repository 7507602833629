import * as React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "./AuthProvider";

interface IRequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<IRequireAuthProps> = (props) => {
  let { authenticated } = useAuth();
  let [searchParams] = useSearchParams();

  if (searchParams.get("global-error")) {
    return <Navigate to="/global-error" replace />;
  } else if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  return props.children;
};

export default RequireAuth;
