import React, {createContext, FC, useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";
import CookieConsentBanner from "../components/HomePage/CookieConsentBanner/CookieConsentBanner";
import Footer from "../components/HomePage/Footer/Footer";
import HeaderNav from "../components/HomePage/HeaderNav/HeaderNav";
import {HomePageData} from "../data/apiData";
import {createSecureHttpClient} from "../utils/HttpClient";
import "./Home.scss";
import {ROOT} from "../constants/routePaths";
import ReactGA from "react-ga4";
import {LANDING_PAGE_AUTHENTICATED_DATA_URL} from "../constants/urls";
import ChatBot from "../components/Common/ChatBot/ChatBot";

export const homePageContext = createContext<HomePageData>(null!);

interface Props {}

const Home: FC<Props> = () => {
  const navigate = useNavigate();
  const { getToken, logout } = useAuth();
  const [homeData, setHomeData] = useState<HomePageData>(null!);
  useEffect(() => {
    createSecureHttpClient(getToken()!)
      .get<HomePageData>(LANDING_PAGE_AUTHENTICATED_DATA_URL)
      .then((response) => {
        setHomeData(response.data);
        ReactGA.set({ userId: response.data.userInfo.loginId });
      })
      .catch((error) => {
        ReactGA.set({ userId: null });
        logout();
        navigate(ROOT, { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tds-app">
      <homePageContext.Provider value={homeData}>
        <HeaderNav />

        <Outlet />

        <Footer/>

        <CookieConsentBanner />

        <ChatBot/>
      </homePageContext.Provider>
    </div>
  );
};

export default Home;
