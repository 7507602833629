import * as React from "react";
import { FC } from "react";
import "./AppAccessRequest.scss";
import { Application, Field, FieldType } from "../../../data/apiData";
import { ApplicationDto } from "./AppAccessRequestUtils";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage";
import SingleSelectField from "./SingleSelectField";
import CheckboxField from "./CheckboxField";
import MultiSelectField from "./MultiSelectField";
import TextInputField from "./TextInputField";
import { isEnglish } from "../../../utils/Utils";

interface Props {
  validated: boolean;
  application: Application;
  appDto: ApplicationDto;
  handleFieldValueChanged: (
    appId: string,
    fieldId: number,
    value: string | boolean | number | number[]
  ) => void;
}

const AppAccessRequest: FC<Props> = ({
  validated,
  application,
  handleFieldValueChanged,
  appDto,
}) => {
  const getApplicationFields = (appId: string, fields?: Field[]) => {
    if (fields) {
      const sortedFields = [...fields].sort((f1, f2) => f1.order - f2.order);

      return sortedFields.map((field) => {
        switch (field.type) {
          case FieldType.MULTI_SELECT:
            return (
              <MultiSelectField
                key={field.id}
                validated={validated}
                appId={appId}
                field={field}
                appDto={appDto}
                handleFieldValueChanged={handleFieldValueChanged}
              />
            );
          case FieldType.SINGLE_SELECT:
            return (
              <SingleSelectField
                key={field.id}
                validated={validated}
                appId={appId}
                field={field}
                appDto={appDto}
                handleFieldValueChanged={handleFieldValueChanged}
              />
            );
          case FieldType.TEXT:
            return (
              <TextInputField
                key={field.id}
                validated={validated}
                appId={appId}
                field={field}
                appDto={appDto}
                handleFieldValueChanged={handleFieldValueChanged}
              />
            );
          case FieldType.CHECKBOX:
            return (
              <CheckboxField
                key={field.id}
                validated={validated}
                appId={appId}
                field={field}
                appDto={appDto}
                handleFieldValueChanged={handleFieldValueChanged}
              />
            );
          default:
            console.error("Field not supported");
            return <></>;
        }
      });
    }
  };

  const getAppInfo = (application: Application) => {
    if (
      application.fields?.length! > 0 ||
      application.header ||
      application.footer
    ) {
      return (
        <div
          key={"app_request_id_" + application.id}
          className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding"
        >
          <div className="font-weight-bold">
            {isEnglish() ? application.name : application.frenchName}
          </div>
          <div className="request-app-access-header">
            {isEnglish() ? application.header : application.headerFr}
          </div>
          <div className="request-app-access-fields">
            {getApplicationFields(application.id, application.fields)}
          </div>
          <div className="request-app-access-footer">
            {isEnglish() ? application.footer : application.footerFr}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return <div>{getAppInfo(application)}</div>;
};

export const showErrorMessage = (msg: any, name: any) => {
  return (
    <div className="tds-landing-request-access-form-error">
      <ErrorMessage message={msg} fieldId={name} />
    </div>
  );
};

export default AppAccessRequest;
