import React, { FC } from "react";
import "./CustomCheckbox.scss";
import { useTranslation } from "react-i18next";

interface CustomCheckboxProps {
  value: number | string;
  labelText: string;
  selected: boolean;
  style?: any;
  required?: boolean;
  onBlur?: () => void;
  handleClicked: (value: any, isChecked: boolean) => void;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({
  selected,
  value,
  handleClicked,
  required,
  onBlur,
  labelText,
  style,
}) => {
  const { t } = useTranslation();
  return (
    <div style={style}>
      <div
        className={`form-check custom-checkbox-filter-text ${
          required && "custom-checkbox-filter-asterisk"
        }`}
      >
        <input
          type="checkbox"
          id={"category_" + value + "_" + labelText}
          name={labelText}
          value={value}
          checked={selected}
          onChange={(event) =>
            handleClicked(value, event.currentTarget.checked)
          }
          onBlur={onBlur}
          aria-label={t("checkbox")+labelText}
        ></input>
        <label
          htmlFor={"category_" + value + "_" + labelText}
          className="custom-checkbox-filter-label" tabIndex={0} aria-label={labelText}
        >
          {labelText}
        </label>
      </div>
    </div>
  );
};

export default CustomCheckbox;
