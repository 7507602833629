import { FC, useEffect, useState } from "react";
import background from "../../../assets/images/Banner-Image.webp";
import contactUsImg from "../../../assets/images/contactus-en.svg";
import calladvisorImg from "../../../assets/images/calladvisor02-en.svg";
import { useAuth } from "../../../auth/AuthProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CONTACT_CATEGORY,
  LANDING_LEARN,
  LEARN,
} from "../../../constants/routePaths";
import "./ContactUs.scss";
import { createHttpClient } from "../../../utils/HttpClient";
import { ContentCategory } from "./ContactUsContent";
import Select, { SelectOption } from "../../Common/Select/Select";
import {
  handleDropdownValues,
  setSelectedOption,
  isEnglish,
  isNotEmpty,
  isEmpty,
} from "../../../utils/Utils";
import { ReactSelectDesktopStyles } from "../../../constants/reactSelect";

interface Props {}

const ContactUs: FC<Props> = () => {
  let { authenticated } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = useState<ContentCategory>();
  const [options, setOptions] = useState<any>();
  const [contactStatusObj, setContactStatusObj] = useState<SelectOption>(null!);
  const [changed, setChanged] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<string>("");

  useEffect(() => {
    try {
    createHttpClient()
      .get<ContentCategory>(CONTACT_CATEGORY)
      .then((resp) => {
        setData(resp.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    document.title = t("browser.title.contact.us.page");
    const getOptionsList = (dataObj: any) => {
      const contactCategoriesOptions = dataObj?.contactCategories?.map(
        (category: any) => {
          return {
            value: category?.nameEn,
            label: isEnglish() ? category?.nameEn : category?.nameFr,
          };
        }
      );
      setOptions(contactCategoriesOptions);
      if (isNotEmpty(selectedValue)) {
        setContactStatusObj!(
          setSelectedOption(
            handleDropdownValues(contactCategoriesOptions, selectedValue)
          )
        );
      }
    };
    getOptionsList(data);
  }, [t, data, selectedValue]);

  const handleContactStatus = (contactStatus: any) => {
    setSelectedValue(contactStatus);
    setContactStatusObj!(
      setSelectedOption(handleDropdownValues(options, contactStatus))
    );
    return getContactCategoryDetails(
      getFilteredContactCategory(contactStatus)?.[0]
    );
  };

  const getContactCategoryBySelectedValue = (value: string) => {
    return getContactCategoryDetails(getFilteredContactCategory(value)?.[0]);
  };

  const getFilteredContactCategory = (value: string) => {
    const category = data?.contactCategories?.filter(
      (cat) => value === cat.nameEn
    );
    return category;
  };

  const getContactCategoryDetails = (category: any) => {
    const email = isEnglish()
      ? category?.emailContentEn
      : category?.emailContentFr;
    const phoneNumber = isEnglish()
      ? category?.phoneContentEn
      : category?.phoneContentFr;
    return (
      <>
        <div
          className={`contact-us-help-detail-container ${
            isEmpty(email) || isEmpty(phoneNumber) ? "one-column" : ""
          }`}
          key={"id_" + category?.nameEn}
        >
          {isNotEmpty(email) ? getEmailInfo(email) : ""}
          {isNotEmpty(phoneNumber)
            ? getPhoneNumberInfo(phoneNumber, email)
            : ""}
        </div>
      </>
    );
  };

  const getEmailInfo = (email: string) => {
    return (
      <div>
        <div className="help-detail-img">
          <img src={contactUsImg} alt={t("contact_us")} />
        </div>
        <div className="help-detail-div">
          <div dangerouslySetInnerHTML= {{__html: email}}></div>
        </div>
        
      </div>
    );
  };

  const getPhoneNumberInfo = (phoneNumber: string, email: string) => {
    return (
      <div className={isNotEmpty(email) ? "vl" : ""}>
        <div className="help-detail-img">
          <img src={calladvisorImg} alt={t("contact_us")} />
        </div>
        <div className="help-detail-div">
          <div dangerouslySetInnerHTML= {{__html: phoneNumber}}></div>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  return (
    <div className="tds-contact-us-container">
      <div
        className="tds-contact-us-video-bar"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="tds-contact-us-video-content" tabIndex={0}>
          <section>
            <h1 style={{ marginBottom: 0 }} aria-label={t("contact.us.text1")}>
              {t("contact.us.text1")}
            </h1>
            <h1
              className="landing-main-banner-title"
              aria-label={t("contact.us.text2")}
            >
              {t("contact.us.text2")}
            </h1>
            <button
              onClick={() =>
                authenticated ? navigate(LEARN) : navigate(LANDING_LEARN)
              }
              className="btn btn-block td-btn-primary-light contact-us-learn-more-btn"
            >
              {t("go.to.learn.more.page")}
            </button>
          </section>
        </div>
      </div>
      <div className="contact-us-banner-container contact-us-banner-container-bg-color">
        <div className="contact-us-banner-title" tabIndex={0}>
          {t("contact.us.customer.service.text")}
        </div>
        <div
          className="contact-us-banner-title subtitle"
          tabIndex={0}
        >
          {t("contact.us.info.text")}
        </div>
      </div>
      <br />
      <div className="contact-us-banner-container margins">
        <div
          className="contact-us-dropdown"
          aria-labelledby="contactUs"
          aria-required="true"
        >
          <Select
            name="contactUs"
            value={contactStatusObj}
            options={options}
            onChange={(obj) => handleContactStatus(obj)}
            onBlur={() => {
              setChanged(true);
            }}
            isClearable={true}
            styles={changed && ReactSelectDesktopStyles}
            placeholder={t("contact.us.default.select")}
          />
        </div>
      </div>
      <div className="contact-us-banner-container margins">
        <div className="contact-us-banner-title" tabIndex={0}>
          {isNotEmpty(selectedValue)
            ? getContactCategoryBySelectedValue(selectedValue)
            : getContactCategoryDetails(data?.defaultContactCategory)}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
