import {FC, useContext, useEffect, useState} from "react";
import "./LandingMainContent.scss";
import background from "../../../assets/images/Banner-Image.webp";
import { HomePageCategory } from "../../../data/apiData";
import { useTranslation } from "react-i18next";
import CategoryFilter from "./CategoryFilter";
import RequestAccessBox from "../../LandingPage/RequestAccessBox/RequestAccessBox";
import { useAuth } from "../../../auth/AuthProvider";
import ReactGA from "react-ga4";
import { getAppsWithCategories } from "./CategoryContentUtils";
import { useNavigate } from "react-router-dom";
import { REGISTRATION } from "../../../constants/routePaths";
import {homePagePublicContext} from "../../../pages/Landing";

interface Props {}

const LandingMainContent: FC<Props> = () => {
  let { authenticate } = useAuth();
  const { i18n, t } = useTranslation();
  const homePageData = useContext(homePagePublicContext);
  const [selectedCategories, setSelectedCategories] = useState<
    HomePageCategory[]
  >([]);

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  useEffect(() => {
    document.title = t("browser.title.landing.page");
  },[t]);

  const handleFilterReset = () => {
    setSelectedCategories([]);
  };

  const handleClicked = (id: number, isChecked: boolean) => {
    if (isChecked) {
      const newC = homePageData.categories.find((c) => c.id === id);
      if (newC) {
        setSelectedCategories([...selectedCategories, newC]);
      }
    } else {
      const newC = selectedCategories.filter((c) => c.id !== id);
      setSelectedCategories([...newC]);
    }
  };

  const onLoginClick = () => {
    ReactGA.event({
      action: "login_btn_click",
      category: "landing_page",
    });
    authenticate();
  };
  let navigate = useNavigate();

  return (
    <div className="tds-homepage-container">
      <div
        className="tds-homepage-video-bar"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="tds-homepage-video-content" tabIndex={0}>
          <section>
            <h1 style={{ marginBottom: 0 }} aria-label={t("landing.main.banner.welcome")}>
              {t("landing.main.banner.welcome")}
            </h1>
            <h1 className="landing-main-banner-title" aria-label={t("landing.main.banner.title")}>
              {t("landing.main.banner.title")}
            </h1>
            <button
              onClick={onLoginClick}
              className="tds-homepage-video-bar-button btn btn-block td-btn-secondary-clear"
            >
              {t("login")}
            </button>
          </section>
        </div>
      </div>

      <div className="tds-container landing-main-content-top-container">
        <div className="landing-main-request-access-box">
          <RequestAccessBox
            handleClicked={() => {
              navigate(REGISTRATION);
            }}
          />
        </div>
        <div className="landing-main-content-container">
          {homePageData && (
            <>
              <div className="landing-main-content-filter-section">
                <CategoryFilter
                  allCategories={homePageData.categories}
                  selectedCategories={selectedCategories}
                  handleClicked={handleClicked}
                  handleReset={handleFilterReset}
                />
              </div>
              <div className="landing-main-content-app-section">
                {getAppsWithCategories(
                  selectedCategories.length ? selectedCategories : homePageData.categories,
                  isEnglish()
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingMainContent;
