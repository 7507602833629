import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  APP_CALLBACK_URL_KEY,
  APP_ID_KEY,
  APP_NONCE_KEY,
  APP_STEP_UP_CALLBACK_URL_KEY,
  useAuth,
} from "../auth/AuthProvider";

interface ICallbackProps {}

const Callback: React.FC<ICallbackProps> = () => {
  let [searchParams] = useSearchParams();
  let { callback, authenticated, callbackApp, callbackAppForStepUp, error } =
    useAuth();
  let navigate = useNavigate();

  React.useEffect(() => {
    const appId = sessionStorage.getItem(APP_ID_KEY);
    const appStepUpCallbackUrl = sessionStorage.getItem(
      APP_STEP_UP_CALLBACK_URL_KEY
    );
    const nonce = sessionStorage.getItem(APP_NONCE_KEY);

    if (appId && appStepUpCallbackUrl && nonce) {
      sessionStorage.removeItem(APP_ID_KEY);
      sessionStorage.removeItem(APP_STEP_UP_CALLBACK_URL_KEY);
      sessionStorage.removeItem(APP_NONCE_KEY);
      callbackAppForStepUp(
        searchParams.get("code"),
        appId,
        appStepUpCallbackUrl,
        searchParams.get("state") //state is used to relay since UAP is not OIDC yet, otherwise nonce will be used
      );
    } else {
      if (!authenticated) {
        callback(searchParams.get("code"));
      } else {
        const appCallbackUrl = sessionStorage.getItem(APP_CALLBACK_URL_KEY);
        if (appId && appCallbackUrl) {
          callbackApp(appId, appCallbackUrl);
        } else {
          navigate("/", { replace: true });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  if (error) {
    return <div>Error getting access token: {error}</div>;
  }

  return <></>;
};

export default Callback;
