import * as React from 'react';
import {useTranslation} from "react-i18next";
import './RegistrationConflictErrorMessage.scss';

export enum RegistrationConflictError {
    DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',
    USER_REGISTRATION_SUBMITTED_OR_DENIED = 'USER_REGISTRATION_SUBMITTED_OR_DENIED',
    APP_REGISTRATION_SUBMITTED_OR_DENIED = 'APP_REGISTRATION_SUBMITTED_OR_DENIED',
    DUPLICATE_REQUEST_SHORT_FORM = 'DUPLICATE_REQUEST_SHORT_FORM',
    UNKNOWN_EMAIL_SHORT_FORM = 'UNKNOWN_EMAIL_SHORT_FORM',
    ERROR_SHORT_FORM = 'ERROR_SHORT_FORM'
}

export interface RegistrationConflictErrorMessageProps {
    error: RegistrationConflictError
}

const getMessages = (error: RegistrationConflictError) => {
    switch (error) {
        case RegistrationConflictError.DUPLICATE_REQUEST :
            return ['user.registration.conflict.duplicate.line1', 'user.registration.conflict.duplicate.line2']
        case RegistrationConflictError.DUPLICATE_REQUEST_SHORT_FORM :
            return ['user.registration.conflict.duplicate.short.form.line1']
        case RegistrationConflictError.USER_REGISTRATION_SUBMITTED_OR_DENIED:
            return ['user.registration.conflict.submitted.line1', 'user.registration.conflict.submitted.line2']
        case RegistrationConflictError.APP_REGISTRATION_SUBMITTED_OR_DENIED:
            return ['user.registration.conflict.app-submitted.line1', 'user.registration.conflict.app-submitted.line2']
        case RegistrationConflictError.UNKNOWN_EMAIL_SHORT_FORM:
            return ['user.registration.conflict.email.line1']
        case RegistrationConflictError.ERROR_SHORT_FORM:
            return ['global.error.description']
        default:
            throw new Error('Error is not defined')
    }
}


const RegistrationConflictErrorMessage: React.FC<RegistrationConflictErrorMessageProps> = ({error}) => {
    const {t} = useTranslation();
    const [line1, line2] = getMessages(error);

    return (
        <div className="registration-conflict-error-container">
            <div className="registration-conflict-error-line1">{t(line1)}</div>
            <div>{t(line2)}</div>
        </div>
    )
}

export default RegistrationConflictErrorMessage