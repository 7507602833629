import React, { FC, useContext, useEffect, useState } from "react";
import "./MainContent.scss";
import background from "../../../assets/images/Banner-Image.webp";
import { homePageContext } from "../../../pages/Home";
import { HomePageCategory } from "../../../data/apiData";
import { useTranslation } from "react-i18next";
import CategoryFilter from "./CategoryFilter";

import {
  getAppsWithCategories,
  getAppsWithoutCategories,
} from "./CategoryContentUtils";
import TabNavigator from "../tabNavigator/TabNavigator";

interface Props {}

export enum TabNavigatorOption {
  ALL_APPS = "ALL_APPS",
  FULLY_ONBOARDED_APPS = "FULLY_ONBOARDED_APPS",
}

const MainContent: FC<Props> = () => {
  const { i18n, t } = useTranslation();
  const homePageData = useContext(homePageContext);
  const [selectedCategories, setSelectedCategories] = useState<
    HomePageCategory[]
  >([]);

  const [navigatorValue, setNavigatorValue] = useState<TabNavigatorOption>(
    TabNavigatorOption.FULLY_ONBOARDED_APPS
  );

  const item1 = {
    value: TabNavigatorOption.FULLY_ONBOARDED_APPS,
    name: t("home.main.tab-navigator.my-apps"),
    label: t("home.main.tab-navigator.my-apps.label"),
  };

  const item2 = {
    value: TabNavigatorOption.ALL_APPS,
    name: t("home.main.tab-navigator.all-apps"),
    label: t("home.main.tab-navigator.all-apps.label"),
  };

  useEffect(() => {
    document.title = t("browser.title.landing.page");
  }, [t]);

  const handleFilterReset = () => {
    setSelectedCategories([]);
  };

  const handleClicked = (id: number, isChecked: boolean) => {
    if (isChecked) {
      const newC = homePageData.categories.find((c) => c.id === id);
      if (newC) {
        setSelectedCategories([...selectedCategories, newC]);
      }
    } else {
      const newC = selectedCategories.filter((c) => c.id !== id);
      setSelectedCategories([...newC]);
    }
  };

  const handleTabNavigatorChange = (newValue: TabNavigatorOption) => {
    setNavigatorValue(newValue);
    handleFilterReset();
  };

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  return (
    <div className="tds-homepage-container">
      <div
        className="tds-homepage-video-bar"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="tds-homepage-video-content" tabIndex={0}>
          <section>
            <h1
              aria-label={`${t("home.main.banner.title.hi")} ${
                homePageData?.userInfo.firstName
              },`}
            >{`${t("home.main.banner.title.hi")} ${
              homePageData?.userInfo.firstName
            },`}</h1>
            <h1 aria-label={t("home.main.banner.title.welcome")}>{`${t(
              "home.main.banner.title.welcome"
            )}`}</h1>
            <h1
              className="one-portal-banner-title"
              aria-label={t("home.main.banner.title")}
            >
              {t("home.main.banner.title")}
            </h1>
          </section>
        </div>
      </div>

      <div className="tds-container landing-categories-container">
        <div className="landing-apps-container">
          <div className="main-tab-navigator-container">
            <div className="landing-one-column-tab-navigator" />
            <div className="landing-three-column-tab-navigator">
              <TabNavigator
                item1={item1}
                item2={item2}
                handleOnChange={handleTabNavigatorChange}
              />
            </div>
          </div>
          <div className="landing-filter-and-apps-container">
            {homePageData && (
              <>
                <div className="landing-categories-filter">
                  <CategoryFilter
                    allCategories={homePageData.categories}
                    selectedCategories={selectedCategories}
                    handleClicked={handleClicked}
                    handleReset={handleFilterReset}
                  />
                </div>
                <div className="landing-main-apps-container">
                  {navigatorValue === TabNavigatorOption.FULLY_ONBOARDED_APPS
                    ? getAppsWithoutCategories(
                        selectedCategories.length
                          ? selectedCategories
                          : homePageData.categories,
                        isEnglish()
                      )
                    : getAppsWithCategories(
                        selectedCategories.length
                          ? selectedCategories
                          : homePageData.categories,
                        isEnglish()
                      )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
