import * as React from "react";
import ReactSelect, { SingleValue } from "react-select";

interface SelectProps {
  name: string;
  value?: SelectOption;
  options: SelectOption[];
  styles?: any;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange: (newValue?: string | number) => void;
  onBlur: (newValue?: string) => void;
  fontColor?: string;
  fontWeight?: string;
}

export interface SelectOption {
  value: string | number;
  label: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  value,
  options,
  placeholder,
  isClearable = true,
  isDisabled = false,
  onChange,
  onBlur,
  styles,
}) => {
  return (
    <ReactSelect
      value={value}
      options={options}
      isMulti={false}
      isClearable={isClearable}
      isDisabled={isDisabled}
      name={name}
      styles={styles}
      placeholder={placeholder}
      onChange={(newSelections: SingleValue<SelectOption>) => {
        if (newSelections) {
          onChange(newSelections!.value);
        } else {
          onChange(null!);
        }
      }}
      onBlur={() => onBlur()}
    />
  );
};

export default Select;
