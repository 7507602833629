import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/HomePage/Footer/Footer";
import HeaderNav from "../../components/HomePage/HeaderNav/HeaderNav";
import { LANDING_PAGE_AUTHENTICATED_DATA_URL } from "../../constants/urls";
import { HomePageData } from "../../data/apiData";
import { createSecureHttpClient } from "../../utils/HttpClient";
import "./errors.scss";
import { homePageContext } from "../../pages/Home";
import { useAuth } from "../../auth/AuthProvider";
import ReactGA from "react-ga4";

interface INotAuthorizedProps {}

const NotAuthorized: React.FC<INotAuthorizedProps> = () => {
  const [homePageData, setHomePageData] = useState<HomePageData>(null!);
  const { t } = useTranslation();
  const { getToken } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await createSecureHttpClient(
          getToken()!
        ).get<HomePageData>(LANDING_PAGE_AUTHENTICATED_DATA_URL);
        setHomePageData(response.data);
        ReactGA.set({ userId: response.data.userInfo.loginId });
      } catch (e) {
        ReactGA.set({ userId: null });
        console.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <homePageContext.Provider value={homePageData}>
        <HeaderNav />
      </homePageContext.Provider>
      <>
        <div className="opm-error-container">
          <h1>{t("user.not.authorize")}</h1>
        </div>
      </>
      <Footer />
    </>
  );
};

export default NotAuthorized;
