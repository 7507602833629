import React from "react";
import CategoryContent, {
  CategoryContentItem,
} from "../CategoryContent/CategoryContent";
import {
  Application,
  ApplicationStatus,
  HomePageCategory,
} from "../../../data/apiData";

export const getAppsWithoutCategories = (
  categories: HomePageCategory[],
  isEnglish: boolean
) => {
  const items: CategoryContentItem[] = categories?.flatMap(
    (item: HomePageCategory) => {
      return item.applications
        .filter(
          (a) =>
            a.status !== ApplicationStatus.HIDDEN_ONBOARDED_APP &&
            (a.userHasAccess || a.status === ApplicationStatus.OPEN_APP)
        )
        .map((application: Application) => {
          const item: CategoryContentItem = {
            userHasAccess: application.userHasAccess,
            requestInProcess: application.requestInProcess,
            status: application.status,
            name: isEnglish ? application.name : application.frenchName,
            description: isEnglish
              ? application.description
              : application.frenchDescription,
            link: application.url,
            target: "_" + application.id,
            imageUrl: application.imageUrl,
          };
          return item;
        });
    }
  );
  if (items && items.length) {
    return (
      <React.Fragment key={`category_content_item_all_items`}>
        <CategoryContent items={items} showTitle={false} />
      </React.Fragment>
    );
  }
};

export const getAppsWithCategories = (
  categories: HomePageCategory[],
  isEnglish: boolean
) =>
  categories
    ?.filter((c) => c.applications && c.applications.length > 0)
    .map((item: HomePageCategory) => {
      const linkItems: CategoryContentItem[] = item.applications
        .filter((app) => app.status !== ApplicationStatus.HIDDEN_ONBOARDED_APP)
        .map((application: Application) => {
          const item: CategoryContentItem = {
            userHasAccess: application.userHasAccess,
            requestInProcess: application.requestInProcess,
            status: application.status,
            name: isEnglish ? application.name : application.frenchName,
            description: isEnglish
              ? application.description
              : application.frenchDescription,
            link: application.url,
            target: "_" + application.id,
            imageUrl: application.imageUrl,
          };
          return item;
        });
      if (linkItems?.length > 0) {
        return (
          <React.Fragment key={`category_content_item_${item.name}`}>
            <CategoryContent
              title={isEnglish ? item.name : item.frenchName}
              items={linkItems}
            />
          </React.Fragment>
        );
      }
      return linkItems;
    });
