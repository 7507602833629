import * as React from "react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../Common/customCheckbox/CustomCheckbox";
import { Field } from "../../../data/apiData";
import { ApplicationDto } from "./AppAccessRequestUtils";
import { MESSAGE } from "../../Common/ErrorMessage/ErrorMessagesConstants";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage";

interface Props {
  validated: boolean;
  field: Field;
  appId: string;
  appDto: ApplicationDto;
  handleFieldValueChanged: (
    appId: string,
    fieldId: number,
    value: string | boolean | number | number[]
  ) => void;
}

const CheckboxField: FC<Props> = ({
  validated,
  appId,
  field,
  appDto,
  handleFieldValueChanged,
}) => {
  const { i18n } = useTranslation();
  const [changed, setChanged] = useState<boolean>(false);

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const handleClick = (id: number, checked: boolean) => {
    handleFieldValueChanged(appId, field.id, checked);
  };

  const isSelected = (appId: string, fieldId: number) => {
    const fieldDto = appDto.fields.find((f) => f.id === fieldId);
    return fieldDto ? !!fieldDto.value : false;
  };

  const isFieldSet = (fieldId: number) => {
    return appDto.fields.find((f) => f.id === fieldId)?.value;
  };

  return (
    <div key={"checkbox_" + field.id}>
      <CustomCheckbox
        value={field.id}
        labelText={isEnglish() ? field.displayName : field.displayNameFrench}
        selected={isSelected(appId, field.id)}
        handleClicked={handleClick}
        onBlur={() => setChanged(true)}
        required={true}
      />
      {(changed || validated) && field.required && !isFieldSet(field.id) && (
        <div
          className="tds-landing-request-access-form-error"
          style={{ marginTop: "-10px" }}
        >
          <ErrorMessage
            message={MESSAGE}
            fieldId={isEnglish() ? field.displayName : field.displayNameFrench}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxField;
