export const CONFIRMATION_MSG = "registration.confirmation.message";

export const REQUEST_ACCESS_FORM_NOTES = {
  Foot_Notes: [
    "registration.footnotes1",
    "registration.footnotes2",
    "registration.footnotes3",
  ],
};

export const DEFAULT_STATUS = "default.status";
export const DEFAULT_EMP_SELECT = "default.employee.select";
export const SELECT = "Select";

export const MIFID_II_STATUS_DROPDOWN_LIST = [
  {
    label:
      "Mifid II does not apply to my firm/jurisdiction (i.e. Non-European Client)",
    value: "DOES_NOT_APPLY_NON_EUROPEANS",
  },
  {
    label: "Mifid II applies to my firm/jurisdiction, but I am exempt",
    value: "APPLIES_BUT_EXEMPT",
  },
  {
    label:
      "Mifid II applies to my firm/jurisdiction, but I am covered by a subscription to TD",
    value: "APPLIES_BUT_COVERED_BY_SUBSCRIPTION_TO_TD",
  },
  {
    label:
      "Mifid II applies to my firm/jurisdiction. I would like to apply for a 3-month trial",
    value: "APPLIES_REQUESTING_3_MONTH_TRIAL",
  },
  {
    label:
      "I am a UK investor and agree TD's FICC content is exempt from MiFID II content payment requirements",
    value: "UK_INVESTOR_EXEMPT",
  },
  {
    label:
      "I confirm I meet the MiFID criteria to be deemed a professional or eligible counterparty in the EU and with no TD Sales contact requesting access to TD's FICC content",
    value: "PROFESSIONAL_COUNTERPARTY_EU",
  },
];

export const MII_STATUS_DROPDOWN_LIST = [
  {
    label:
      "Major Institutional Investor (MII) requirements do not apply to my firm (i.e., I am not a U.S. recipient)",
    value: "DOES_NOT_APPLY",
  },
  {
    label:
      "My firm is an asset manager that meets the MII threshold (minimum US$100 million AUM)",
    value: "ASSET_MANAGER_MEETING_THRESHOLD",
  },
  {
    label:
      "My firm is a corporate client that meets the MII threshold (minimum US$100 million in assets)",
    value: "CORPORATE_CLIENT_MEETING_THRESHOLD",
  },
];

export const TD_EMPLOYEE_OPTIONS = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const TD_RELATIONSHIP_OPTIONS = [
  {
    label: "Existing TD Securities Client",
    value: "EXISTING_TDS_CLIENT",
  },
  {
    label: "TD Employee",
    value: "TD_EMPLOYEE",
  },
  {
    label: "Others",
    value: "OTHER",
  },
];

export const TD_EMPLOYEE_LOB = [
  {
    label: "TD Wealth IA",
    value: "TD_WEALTH_IA",
  },
  {
    label: "TD Wealth PAIR",
    value: "TD_WEALTH_PAIR",
  },
  {
    label: "TD Securities",
    value: "TD_SECURITIES",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const TD_EMPLOYEE_DOMAIN = [
  {
    label: "TDBFG",
    value: "TDBFG",
  },
  {
    label: "TDSecurities",
    value: "TD_SECURITIES",
  },
  {
    label: "BKNG",
    value: "BKNG",
  },
  {
    label: "RNET",
    value: "RNET",
  },
  {
    label: "MMI",
    value: "MMI",
  },
];
