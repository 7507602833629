import { FC, ReactNode, useEffect } from 'react';
import "./Modal.scss";

export interface ModalProps {
    id: any,
    className: any,
    children?: ReactNode;
    isOpen: boolean;
    showHideModal: () => void;

    actionButtonClickHandler?: () => void;
    actionButtonText?: string;
  }

const Modal: FC<ModalProps> = (props: ModalProps) => {
    const {id, className, isOpen, children, showHideModal, actionButtonClickHandler, actionButtonText} = props;

      useEffect(() => {
        const handleEscKey = (e: any) => {
            if (isOpen && e.keyCode === 27) {
                showHideModal();
            }
          }
        document.addEventListener('keyup', handleEscKey);
        return () => {
          document.removeEventListener('keyup', handleEscKey);
        };
      }, [isOpen, showHideModal]);

      const handleKeyUp = (e: any) => {
        if (e.keyCode === 13) {
            showHideModal();
        }
      }
   
    return (
        <div id={id} className={isOpen ? "tds-modal active" : "tds-modal"}>
            <div className={'tds-modal-content ' + className}>
            <span 
                className="tds-modal-close td-icon td-icon-close td-interactive-icon"
                onClick={showHideModal}
                onKeyUp={handleKeyUp}
                title="Close"
                tabIndex={0}
            ></span>
                {children}
                {actionButtonClickHandler && actionButtonText &&
                    <div className="modal-action-button-container">
                        <button className="btn btn-block td-btn-primary-light" style={{width: '15%'}} onClick={actionButtonClickHandler}>
                            {actionButtonText}
                        </button>
                    </div>
                }
            </div>
        </div>
    )
};
export default Modal;
