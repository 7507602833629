import React, {FC, useContext, useEffect, useState} from "react";
import "./HeaderNav.scss";
import {useTranslation} from "react-i18next";
import logo from "../../../assets/images/tds_shield.svg";
import HeaderNavDropdown, {HeaderNavDropdownData,} from "../HeaderNavDropdown/HeaderNavDropdown";
import {ROOT} from "../../../constants/routePaths";
import {Link} from "react-router-dom";
import MobileNav, {MobileNavLeft, MobileNavProps,} from "../MobileNav/MobileNav";
import {Language, LinkType} from "../../../constants/types";
import logoFrench from "../../../assets/images/TDS_Header_Logo_FR.svg";
import {Application, HomePageCategory} from "../../../data/apiData";
import {MobileNavDropdownItem} from "../MobileNavDropdown/MobileNavDropdown";
import {homePagePublicContext} from "../../../pages/Landing";

interface Props {
}

const HeaderNavUnAuthenticated: FC<Props> = () => {
    const {i18n, t} = useTranslation()
    const homePageData = useContext(homePagePublicContext);
    const [language, setLanguage] = useState<Language>(Language.en_CA)

    useEffect(() => {
        setLanguage(i18n.language.startsWith("en") ? Language.en_CA : Language.fr_CA)
    }, [i18n])


    /* Language dropdown */
    const handleLanguageChange = (language: Language) => {
        i18n.changeLanguage(language);

        setLanguage(language);
    }

    const isEnglish = () => {
        return language.startsWith("en")
    }

    const english = t("home.right_menu.language.english")
    const french = t("home.right_menu.language.french")

    const englishDropDownData: HeaderNavDropdownData = {
        title: t("home.right_menu.language.title"),
        text: isEnglish() ? english : french,
        dropdownlinkStyle: "tds-top-nav-dropdown-link",
        contentStyle: "language",
        openOnHover: false,
        items: [
            {
                text: english,
                title: english,
                link: "#",
                type: LinkType.LANGUAGE,
                withLanguageCheckMark: isEnglish(),
                language: Language.en_CA,
                onLanguageChange: handleLanguageChange,
            },
            {
                text: french,
                title: french,
                link: "#",
                type: LinkType.LANGUAGE,
                withLanguageCheckMark: !isEnglish(),
                language: Language.fr_CA,
                onLanguageChange: handleLanguageChange,
            },
        ],
    }

    /* Mobile Nav data */
    let categoriesDropDownData: MobileNavLeft[] = []

    if (homePageData) {
        categoriesDropDownData = homePageData.categories.map(
            (item: HomePageCategory, id: number) => {
                const linkItems = item.applications.map((application: Application) => {
                    const itemData: MobileNavDropdownItem = {
                        name: isEnglish() ? application.name : application.frenchName,
                        link: application.url,
                        type: LinkType.EXTERNAL,
                    };

                    return itemData;
                });

                const dropdownData: MobileNavLeft = {
                    type: "dropdown",
                    dropdownData: {
                        name: isEnglish() ? item.name : item.frenchName,
                        items: linkItems,
                    },
                };
                return dropdownData;
            }
        );
    }

    const mobileNavData: MobileNavProps = {
        leftItems: [
            ...Array.from(categoriesDropDownData),
            {
                type: "link",
                linkData: {
                    name: english,
                    link: "/",
                    type: LinkType.LANGUAGE,
                    withLanguageCheckMark: isEnglish(),
                    language: Language.en_CA,
                    onLanguageChange: handleLanguageChange,
                },
            },
            {
                type: "link",
                linkData: {
                    name: french,
                    link: "/",
                    type: LinkType.LANGUAGE,
                    withLanguageCheckMark: !isEnglish(),
                    language: Language.fr_CA,
                    onLanguageChange: handleLanguageChange,
                },
            },
        ]
    }

    return (
        <div>
            <header>
                <div className="header-utility-bar"></div>
                <div className="td-bg-colour-2 tds-header">
                    <div className="tds-container">
                        <div className="tds-section-left">
                            <Link to={ROOT} title={t("td_securities")} className="tds-logo">
                                <img
                                    src={isEnglish()? logo: logoFrench}
                                    title={t("td_securities")}
                                    alt={t("td_securities")}
                                />
                            </Link>
                        </div>
                        <div className="tds-section-right1">
                            <div className="tds-top-nav right">
                                <HeaderNavDropdown {...englishDropDownData} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileNav {...mobileNavData} />
        </div>
    )
}

export default HeaderNavUnAuthenticated
