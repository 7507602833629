import * as React from "react";
import { FC, useState } from "react";
import { Field } from "../../../data/apiData";
import { ApplicationDto } from "./AppAccessRequestUtils";
import { useTranslation } from "react-i18next";
import Select from "../../Common/Select/Select";
import {
  ReactSelectDesktopStyles,
  ReactSelectDesktopStylesError,
} from "../../../constants/reactSelect";
import { SELECT } from "./RegistrationRequestConstants";
import { MESSAGE } from "../../Common/ErrorMessage/ErrorMessagesConstants";
import { showErrorMessage } from "./AppAccessRequest";

interface SingleSelectProps {
  validated: boolean;
  field: Field;
  appId: string;
  appDto: ApplicationDto;
  handleFieldValueChanged: (
    appId: string,
    fieldId: number,
    value: string | boolean | number | number[]
  ) => void;
}

const SingleSelectField: FC<SingleSelectProps> = ({
  validated,
  field,
  appId,
  appDto,
  handleFieldValueChanged,
}) => {
  const { t, i18n } = useTranslation();
  const [changed, setChanged] = useState<boolean>(false);

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const getValue = () => {
    const value = appDto.fields.find((f) => f.id === field.id)?.value;
    if (value) {
      return getOptions().find((o) => o.value === value);
    }
  };
  const getOptions = () => {
    const options = field.options?.map((o) => {
      return {
        value: o.id,
        label: isEnglish() ? o.displayName : o.displayNameFrench,
      };
    });
    return options ? options : [];
  };

  const handleSelectChanged = (newValue: any) => {
    handleFieldValueChanged(appId, field.id, newValue);
  };

  const isFieldSet = (fieldId: number) => {
    return appDto.fields.find((f) => f.id === fieldId)?.value;
  };

  return (
    <div
      key={"single_select_id_" + field.id}
      className={`tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding ${
        field.required && "tds-landing-form-required"
      }`}
    >
      <label id={"label_id_" + field.id} htmlFor={"id_" + field.id}>
        {isEnglish() ? field.displayName : field.displayNameFrench}
      </label>
      <div
        className="tds-td-employee-form-dropdown mi-status"
        aria-labelledby={
          isEnglish() ? field.displayName : field.displayNameFrench
        }
        aria-required="true"
      >
        <Select
          name={field.name}
          value={getValue()}
          options={getOptions()}
          onChange={handleSelectChanged}
          onBlur={() => {
            setChanged(true);
          }}
          isClearable={true}
          styles={
            changed && field.required && !isFieldSet(field.id)
              ? ReactSelectDesktopStylesError
              : ReactSelectDesktopStyles
          }
          placeholder={t(SELECT)}
        />
        {(changed || validated) &&
          field.required &&
          !isFieldSet(field.id) &&
          showErrorMessage(
            t(MESSAGE),
            isEnglish() ? field.displayName : field.displayNameFrench
          )}
      </div>
    </div>
  );
};

export default SingleSelectField;
