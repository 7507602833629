import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "./CustomTooltip.scss";

interface Props {
  id: string;
  descriptorText: string;
  toolTipMessage: string;
  containerClassName: string;
  toolTipDescriptorClass: string;
}
const CustomTooltip: FC<Props> = ({
  id,
  descriptorText,
  toolTipMessage,
  containerClassName,
  toolTipDescriptorClass,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const showHideTooltip = (open: boolean) => {
    setIsOpen(open);
  };

  const handleKeyUp = (e: any, open: boolean) => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      setIsOpen(open);
    }
  };

  return (
    <React.Fragment>
      <div
        data-tooltip-id={id}
        data-tooltip-place="bottom"
        data-tooltip-variant="light"
        onClick={() => showHideTooltip(!isOpen)}
        onKeyUp={(e) => handleKeyUp(e, true)}
        className={toolTipDescriptorClass}
        tabIndex={0}
      >
        {t(descriptorText)}
      </div>
      <Tooltip
        id={id}
        openOnClick={true}
        className={containerClassName}
        classNameArrow="tooltip-arrow"
        clickable={true}
        isOpen={isOpen}
      >
        <div
          aria-required="false"
          tabIndex={0}
          onKeyUp={(e) => handleKeyUp(e, false)}
        >
          <span
            className="tooltip-close td-icon td-icon-close td-interactive-icon"
            onClick={() => showHideTooltip(false)}
            onKeyUp={(e) => handleKeyUp(e, false)}
            title={t("close")}
            tabIndex={0}
          ></span>
          {t(toolTipMessage)}
        </div>
      </Tooltip>
    </React.Fragment>
  );
};
export default CustomTooltip;
