import React, { FC, useState } from "react";
import "./HeaderNavDropdown.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {Language, LinkType} from "../../../constants/types";

export interface HeaderNavDropdownItemData {
  text: string;
  title: string;
  link: string;
  type: LinkType;
  withLanguageCheckMark?: boolean;
  language?: Language;
  onLanguageChange?: (language: Language) => void;
  onInternalLinkChange?: () => void;
}

export interface HeaderNavDropdownData {
  text: string;
  title: string;
  dropdownlinkStyle: "tds-top-nav-dropdown-link" | "tds-navbar-dropdown-link";
  contentStyle: string;
  openOnHover: boolean;
  items: HeaderNavDropdownItemData[];
}

const HeaderNavDropdown: FC<HeaderNavDropdownData> = ({
  text,
  title,
  dropdownlinkStyle,
  contentStyle,
  openOnHover,
  items,
}: HeaderNavDropdownData) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOnClick = () => {
    setOpen(!open);
  };

  const handleOnMouseEnter = () => {
    setOpen(openOnHover);
  };

  const handleOnMouseLeave = () => {
    setOpen(false);
  };

  const routeChange = (link: string) => {
    navigate(link, { replace: true });
  };

  const navigateExternal = (link: string) => {
    window.location.href = link;
  };

  const listItems = items.map((item: HeaderNavDropdownItemData, id: number) => {
    return (
      <React.Fragment key={`item_${id}`}>
        {item.type === LinkType.INTERNAL && (
          <li
            onClick={() => {
              item.onInternalLinkChange!();
              routeChange(item.link);
            }}
          >
            <Link to={item.link} title={item.title}>
              {item.text}
            </Link>
          </li>
        )}

        {item.type === LinkType.EXTERNAL && (
          <li onClick={() => navigateExternal(item.link)}>
            <a
              href={item.link}
              title={item.text}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.text}
            </a>
          </li>
        )}

        {item.type === LinkType.LANGUAGE && (
          <li onClick={() => item.onLanguageChange!(item.language!)}>
            <a href={item.link} title={item.text}>
              {item.text}
            </a>
            {item.withLanguageCheckMark && (
              <span className="td-icon td-icon-checkMark icon-small"></span>
            )}
          </li>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <div
        className="tds-nav-item"
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <a
          href="# "
          title={title}
          className={`${dropdownlinkStyle} ${open ? "active" : ""}`}
        >
          {text}
          <span className="td-icon td-icon-downCaret icon-tiny"></span>
        </a>

        {open && (
          <ul
            className={`tds-dropdown-content active ${
              contentStyle ? contentStyle : ""
            }`}
          >
            {listItems}
          </ul>
        )}
      </div>
    </>
  );
};

export default HeaderNavDropdown;
