import { FC, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import {
  COOKIE_CONSENT_COOKIE_EXPIRATION,
  COOKIE_CONSENT_COOKIE_NAME,
} from "../../../constants/constants";
import { removeTracking } from "../../../utils/CookieConsentUtil";
import CustomCheckbox from "../../Common/customCheckbox/CustomCheckbox";
import "./Preferences.scss";

interface Props {}

const Preferences: FC<Props> = () => {
  const [cookies, setCookie] = useCookies([COOKIE_CONSENT_COOKIE_NAME]);
  const [consent, setConsent] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("browser.title.ad.choices.page");
  }, [t]);

  useEffect(() => {
    setConsent("true" === cookies[COOKIE_CONSENT_COOKIE_NAME]);
  }, [cookies]);

  const handleConsentChange = () => {
    let expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + COOKIE_CONSENT_COOKIE_EXPIRATION);

    let trackingEnabled = "true" !== cookies[COOKIE_CONSENT_COOKIE_NAME];

    setCookie(COOKIE_CONSENT_COOKIE_NAME, trackingEnabled ? "true" : "false", {
      path: "/",
      expires: expiryDate,
    });

    if (!trackingEnabled) {
      removeTracking();
    }
    window.location.reload();
  };

  //set focus on the h1, otherwise it stays on the footer 'Ad choices' link
  const titleRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    titleRef?.current?.focus();
  });

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  return (
    <>
      <div className="tds-homepage-container preferences" tabIndex={0} ref={titleRef}>
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h1 ref={titleRef}>
            {t("preferences.title")}
          </h1>
          <p tabIndex={0}>{t("preferences.description")}</p>
          <div className="tds-sn-tabs-container">
            <div className="tds-container">
              <nav aria-label={t("preferences.personalization")} tabIndex={0}>
                <ol>
                  {/* <li className="">
                    <button title="Add Choices">Ad Choices</button>
                  </li> */}
                  <li className="active">
                    <button title={t("preferences.personalization")}>
                      {t("preferences.personalization")}
                    </button>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="tds-divider-space-10">&nbsp;</div>
          <p tabIndex={0}>{t("preferences.personalization.information")}</p>
          <div className="rte">
            <ul>
              <li>
                <strong tabIndex={0}>{t("preferences.personalization.content")}</strong>
                <br />
                <div tabIndex={0}>{t("preferences.personalization.content.description")}</div>
                <p></p>
                <CustomCheckbox
                  key="check_per"
                  value="ens_perCheck"
                  labelText={t("preferences.personalization.no.content")}
                  selected={consent ? false : true}
                  handleClicked={handleConsentChange}
                />
              </li>

              <li>
                <strong tabIndex={0}>
                  {t("preferences.personalization.esential.title")}
                </strong>
                <br />
                <div tabIndex={0}>{t("preferences.personalization.esential.description")}</div>
                <p></p>
                <div tabIndex={0}>{t("preferences.personalization.esential.description.2")}</div>
              </li>
            </ul>

            <div className="td-row">
              <div className="td-col-xs-12">
                <hr className="td-thick-divider-line" />
              </div>
            </div>

            <p tabIndex={0}>
              {t("preferences.personalization.learn")}{" "}
              <a
                href={
                  isEnglish()
                    ? "https://www.tdsecurities.com/ca/en/privacy-security"
                    : "https://www.tdsecurities.com/ca/fr/privacy-security"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("preferences.personalization.privacy")}<span aria-label="Link"></span>
              </a>
              .<br />
            </p>

            <div className="tds-divider-space-10">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
