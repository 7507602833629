import React, { FC } from "react";
import { ApplicationStatus, HomePageCategory } from "../../../data/apiData";
import { useTranslation } from "react-i18next";
import "./CategoryFilter.scss";
import CustomCheckbox from "../../Common/customCheckbox/CustomCheckbox";

interface CategoryFilterProps {
  allCategories: HomePageCategory[];
  selectedCategories: HomePageCategory[];
  handleClicked: (categoryId: number, set: boolean) => void;
  handleReset: () => void;
}

const CategoryFilter: FC<CategoryFilterProps> = ({
  allCategories,
  selectedCategories,
  handleClicked,
  handleReset,
}) => {
  const { t, i18n } = useTranslation();

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const getCategories = (
    allCategories: HomePageCategory[],
    selectedCategories: HomePageCategory[]
  ) => {
    const categories: HomePageCategory[] = [];
    allCategories.forEach((category) => {
      if (category.applications?.length > 0) {
        const apps = category.applications.filter(
          (app) => app.status !== ApplicationStatus.HIDDEN_ONBOARDED_APP
        );
        if (apps?.length > 0) {
          categories.push(category);
        }
      }
    });
    return categories.map((category) => {
      const name = isEnglish() ? category.name : category.frenchName;
      return (
        <CustomCheckbox
          key={category.id}
          value={category.id}
          labelText={name}
          selected={selectedCategories.some((s) => s.id === category.id)}
          handleClicked={handleClicked}
        />
      );
    });
  };

  return (
    <div>
      <h4
        className="font-weight-bold"
        aria-label={t("category.filter")}
        tabIndex={0}
      >
        {t("home.filter.title")}
      </h4>
      <div className="category-filter-container">
        <form>
          {getCategories(allCategories, selectedCategories)}
          <div className="category-filter-reset-button-container">
            <button
              type="button"
              className="tds-homepage-video-bar-button btn btn-block td-btn-secondary-clear category-filter-reset-button"
              aria-label={t("clear.category.filter")}
              onClick={handleReset}
            >
              {t("home.filter.reset")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryFilter;
