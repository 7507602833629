import React from "react";
import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Callback from "./pages/Callback";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./auth/AuthProvider";
import RequireAuth from "./auth/RequireAuth";
import "./services/i18n";
import ReactGA from "react-ga4";
import {
  ROOT,
  LOGIN,
  CALLBACK,
  AD_CHOICES,
  LANDING_AD_CHOICES,
  UAP_REDIRECT,
  LOGOUT,
  LEARN,
  REGISTRATION,
  REQUEST_ACCESS_CONFIRMATION,
  ERROR_NOT_ACTIVE,
  ERROR_NOT_AUTHORIZED,
  LANDING_LEARN,
  ERROR,
  CONTACT_US,
  LANDING_CONTACT_US, SHORT_REGISTRATION
} from "./constants/routePaths";
import Preferences from "./components/HomePage/Preferences/Preferences";
import MainContent from "./components/HomePage/MainContent/MainContent";
import Logout from "./pages/Logout";
import { getTrackerId, removeTracking } from "./utils/CookieConsentUtil";
import { useCookies } from "react-cookie";
import { COOKIE_CONSENT_COOKIE_NAME } from "./constants/constants";
import LearnMore from "./components/HomePage/LearnMore/LearnMore";
import Landing from "./pages/Landing";
import RegistrationRequest from "./components/HomePage/RegistrationRequest/RegistrationRequest";
import RequestAccessConfirmation from "./components/HomePage/RegistrationRequest/RequestAccessConfirmation";
import LandingMainContent from "./components/HomePage/MainContent/LandingMainContent";
import NotActive from "./pages/errors/NotActive";
import NotAuthorized from "./pages/errors/NotAuthorized";
import GlobalError from "./pages/GlobalError";
import reportWebVitals from "./reportWebVitals";
import ContactUs from "./components/HomePage/ContactUs/ContactUs";
import ShortRegistrationRequest from "./components/HomePage/RegistrationRequest/ShortRegistrationRequest";

const App: React.FC = () => {
  const location = useLocation();
  const [cookies] = useCookies([COOKIE_CONSENT_COOKIE_NAME]);

  React.useEffect(() => {
    if ("true" === cookies[COOKIE_CONSENT_COOKIE_NAME]) {
      ReactGA.initialize("G-" + getTrackerId(), {
        gaOptions: {
          //debug_mode: true,
        },
      });
    } else {
      removeTracking();
    }
  }, [cookies]);

  React.useEffect(() => {
    if ("true" === cookies[COOKIE_CONSENT_COOKIE_NAME]) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });

      //https://github.com/react-ga/react-ga/issues/504
      reportWebVitals(({ id, name, value, delta }) =>
        ReactGA.event({
          action: name,
          category: "Web Vitals",
          label: id,
          nonInteraction: true,
          value: delta,
          //value: Math.round(name === "CLS" ? value * 1000 : value)
        })
      );
    }
  }, [location, cookies]);

  return (
    <AuthProvider>
      <Routes>
        <Route
          path={ROOT}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route index element={<MainContent />} />
          <Route path={LEARN} element={<LearnMore />} />
          <Route path={AD_CHOICES} element={<Preferences />} />
          <Route path={CONTACT_US} element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={LOGIN} element={<Landing />}>
          <Route index element={<LandingMainContent />} />
        </Route>

        <Route path={LANDING_AD_CHOICES} element={<Landing />}>
          <Route index element={<Preferences />} />
        </Route>

        <Route path={LANDING_LEARN} element={<Landing />}>
          <Route index element={<LearnMore />} />
        </Route>

        <Route path={LANDING_CONTACT_US} element={<Landing />}>
          <Route index element={<ContactUs />} />
        </Route>


        <Route path={CALLBACK} element={<Callback />} />
        <Route path={REGISTRATION} element={<RegistrationRequest />} />

        <Route path={`${SHORT_REGISTRATION}/:app`} element={<Landing />}>
          <Route index element={<ShortRegistrationRequest />} />
        </Route>

        <Route
          path={REQUEST_ACCESS_CONFIRMATION}
          element={<RequestAccessConfirmation />}
        />
        <Route path={LOGOUT} element={<Logout />} />
        {/* the below applies in localhost:3000, in localhost:8080 or dev/pat/prd
        it will get intercepted by spring boot controller with the same path
        /security/uap-redirect */}
        <Route path={UAP_REDIRECT} element={<NotFound />} />
        <Route path={ERROR_NOT_ACTIVE} element={<NotActive />} />
        <Route path={ERROR_NOT_AUTHORIZED} element={<NotAuthorized />} />
        <Route path={ERROR} element={<GlobalError />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
