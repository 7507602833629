import * as React from "react";
import { useAuth } from "../../auth/AuthProvider";
import "./errors.scss";
import { useTranslation } from "react-i18next";
import Footer from "../../components/HomePage/Footer/Footer";
import HeaderNavUnAuthenticated from "../../components/HomePage/HeaderNav/HeaderNavUnAuthenticated";
import ReactGA from "react-ga4";

interface INotActiveProps {}

const NotActive: React.FC<INotActiveProps> = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      ReactGA.set({ userId: null });
    };
    logout();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderNavUnAuthenticated />
      <>
        <div className="opm-error-container">
          <h1>{t("user.not.active")}</h1>
        </div>
      </>
      <Footer />
    </>
  );
};

export default NotActive;
