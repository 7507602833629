import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";
import detector from "i18next-browser-languagedetector";
import {Language} from "../constants/types";

const resources = {
    fr_CA: {
      translation: translationFR
    },
    en_CA: {
      translation: translationEN
    }
};

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Language.en_CA,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;
