import React, {FC, useState} from "react";
import {TabNavigatorOption} from "../MainContent/MainContent";
import "./TabNavigator.scss";

interface TabNavigatorItem {
    value: TabNavigatorOption;
    name: string;
    label: string;
}

interface TabNavigatorProps {
    item1: TabNavigatorItem;
    item2: TabNavigatorItem;
    handleOnChange: (newValue: TabNavigatorOption) => void;
}

const TabNavigator: FC<TabNavigatorProps> = ({item1, item2, handleOnChange}) => {
    const [selected, setSelected] = useState<string>(item1.value);

    const onChange = (newItem: TabNavigatorItem) => {
        setSelected(newItem.value);
        handleOnChange(newItem.value);
    };

    return (
        <React.Fragment>
            <div className="tds-tab-menu-container">
                <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                    <div className="td-multi-single-select-buttons-group">
                        <div
                            className="td-multi-single-select-button-wrapper"
                            onClick={() => onChange(item1)}
                        >
                            <input
                                onChange={() => onChange(item1)}
                                type="radio"
                                value={item1.value}
                                name="selectButtonSegmented1G"
                                id="selectButtonSegmented1"
                                checked={selected === item1.value}
                                aria-label={item1.label}
                            />
                            <label
                                htmlFor="selectButtonSegmented1G"
                                title={item1.name}
                            >
                                {item1.name}
                            </label>
                        </div>
                        <div
                            className="td-multi-single-select-button-wrapper"
                            onClick={() => onChange(item2)}
                        >
                            <input
                                onChange={() => onChange(item2)}
                                type="radio"
                                value={item2.value}
                                name="selectButtonSegmented2G"
                                id="selectButtonSegmented2"
                                checked={selected === item2.value}
                                aria-label={item2.label}
                            />
                            <label
                                htmlFor="selectButtonSegmented2G"
                                title={item2.name}
                            >
                                {item2.name}
                            </label>
                        </div>
                    </div>
                </fieldset>
            </div>
        </React.Fragment>
    );
};

export default TabNavigator;
