import * as React from "react";
import "./ErrorMessage.scss";

export interface ErrorMessageProps {
  message?: string;
  fieldId?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, fieldId }) => {
  return (
    <div className="td-error">
        <div role="alert" id={fieldId} aria-hidden="false"><span className="td-error-symbol" aria-hidden="true"></span><span className="sr-only">Error:</span>{message}</div>
    </div> )
};

export default ErrorMessage;
