import React, {FC, useEffect, useState} from "react";
import "./MobileNav.scss";
import logo from "../../../assets/images/tds_shield.svg";
import logoFrench from "../../../assets/images/TDS_Header_Logo_FR.svg";
import {useTranslation} from "react-i18next";
import MobileNavLink, {MobileNavLinkProps,} from "../MobileNavLink/MobileNavLink";
import MobileNavDropdown, {MobileNavDropdownProps,} from "../MobileNavDropdown/MobileNavDropdown";
import {Link} from "react-router-dom";
import {ROOT} from "../../../constants/routePaths";
import {Language} from "../../../constants/types";

export interface MobileNavProps {
  leftItems: MobileNavLeft[];
  right?: MobileNavRight;
}

export interface MobileNavRight {
  username: string;
  items: MobileNavLinkProps[];
}

export interface MobileNavLeft {
  type: "link" | "dropdown";
  linkData?: MobileNavLinkProps;
  dropdownData?: MobileNavDropdownProps;
}

const MobileNav: FC<MobileNavProps> = ({ leftItems, right }) => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState<Language>(Language.en_CA)

  useEffect(() => {
    setLanguage(i18n.language.startsWith("en") ? Language.en_CA : Language.fr_CA)
  }, [i18n, i18n.language])

  const isEnglish = () => {
      return language.startsWith("en")
  }

  const [leftNavOpen, setLeftNavOpen] = useState<boolean>(false);
  const [rightNavOpen, setRightNavOpen] = useState<boolean>(false);

  const handleOpenNavLeft = () => {
    setLeftNavOpen(!leftNavOpen);
  };

  const handleOpenNavRight = () => {
    setRightNavOpen(!rightNavOpen);
  };

  const handleInternalLinkChange = () => {
    setLeftNavOpen(false);
    setRightNavOpen(false);
  };

  const logoLink = (
    <Link to={ROOT} title={t("td_securities")} className="tds-logo-mobile">
      <img src={isEnglish()? logo : logoFrench} title={t("td_securities")} alt={t("td_securities")} />
    </Link>
  );

  const listLeftItems = leftItems.map((item: MobileNavLeft, id: number) => {
    return (
      <React.Fragment key={`mobile_nav_left_item_${id}`}>
        {item.type === "link" && (
          <MobileNavLink
            name={item.linkData!.name}
            link={item.linkData!.link}
            type={item.linkData!.type}
            withLanguageCheckMark={item.linkData!.withLanguageCheckMark}
            language={item.linkData!.language}
            onLanguageChange={item.linkData!.onLanguageChange}
            onInternalLinkChange={handleInternalLinkChange}
          />
        )}

        {item.type === "dropdown" && (
          <MobileNavDropdown
            name={item.dropdownData!.name}
            items={item.dropdownData!.items}
          />
        )}
      </React.Fragment>
    );
  });

  const listRightItems = right?.items.map(
    (item: MobileNavLinkProps, id: number) => {
      return (
        <React.Fragment key={`mobile_nav_right_item_${id}`}>
          <MobileNavLink
            name={item.name}
            link={item.link}
            type={item.type}
            onInternalLinkChange={item.onInternalLinkChange}
          />
        </React.Fragment>
      );
    }
  );

  return (
    <header className="tds-mobile-nav">
      <div className="tds-mobile-nav-header">
        <span
          className="td-icon td-icon-hamburger icon-mobile"
          onClick={handleOpenNavLeft}
        ></span>
        {logoLink}
        {right &&
        <span
          className="td-icon td-icon-avatar icon-mobile"
          onClick={handleOpenNavRight}
        ></span>
        }
      </div>
      <div
        className={
          `tds-mobile-nav-background-overlay` +
          (leftNavOpen || rightNavOpen ? " active" : "")
        }
      ></div>
      <div className={`tds-mobile-nav-left` + (leftNavOpen ? " active" : "")}>
        <div className="tds-mobile-nav-logo-container">
          {logoLink}
          <span
            className="td-icon td-icon-close icon-mobile"
            onClick={handleOpenNavLeft}
          ></span>
        </div>
        <nav className="tds-mobile-nav-container">
          <div className="tds-mobile-navbar">{listLeftItems}</div>
        </nav>
      </div>
      {right &&
      <div className={`tds-mobile-nav-right` + (rightNavOpen ? " active" : "")}>
        <div className="tds-mobile-nav-logo-container">
          <span className="td-icon td-icon-avatar icon-mobile"></span>
          <span className="tds-mobile-nav-username">{right.username}</span>
          <span
            className="td-icon td-icon-close icon-mobile"
            title="Close User Menu"
            onClick={handleOpenNavRight}
          ></span>
        </div>
        <nav className="tds-mobile-nav-container">
          <div className="tds-mobile-navbar">{listRightItems}</div>
        </nav>
      </div>
      }
    </header>
  );
};

export default MobileNav;
