export interface HomePageData {
  categories: [HomePageCategory];
  userInfo: {
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    telephoneNumber: string;
    loginId: string;
  };
  contactUs: ContactUs;
}

export interface HomePageCategory {
  id: number;
  name: string;
  frenchName: string;
  applications: [Application];
}

export enum ApplicationStatus {
  OPEN_APP = "OPEN_APP",
  FULLY_ONBOARDED = "FULLY_ONBOARDED",
  EXTERNAL_LOGIN = "EXTERNAL_LOGIN",
  HIDDEN_ONBOARDED_APP = "HIDDEN_ONBOARDED_APP",
  ONBOARDED_SSO_ONLY_APP = "ONBOARDED_SSO_ONLY_APP", //no UI "Register" option for the user
  COMING_SOON = "COMING_SOON",
  // similar to EXTERNAL_LOGIN but short registration form is accessible for onboarding users,
  // when onboarding is done, app is switched to FULLY_ONBOARDED
  EXTERNAL_APP_TO_BE_ONBOARDED = "EXTERNAL_APP_TO_BE_ONBOARDED"
}

export interface Application {
  id: string;
  name: string;
  frenchName: string;
  description: string;
  frenchDescription: string;
  url: string;
  imageUrl: string;
  userHasAccess: true;
  requestInProcess: false;
  status: ApplicationStatus;
  header: string;
  headerFr: string;
  footerFr: string;
  footer: string;
  nameAlias: string;
  fields?: Field[];
}

export type UserRequestStatus = "NEW_REQUEST" | "APPROVED" | "DENIED";

export interface NewUserRequest {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  telephoneNumber: string;
  status?: UserRequestStatus;
  createdAt?: string;
  createdBy?: string;
  comment?: string;
}

export interface ContactUs {
  email: string;
  phone: string;
}

export interface Field {
  id: number;
  type: FieldType;
  name: string;
  displayName: string;
  displayNameFrench: string;
  order: number;
  required: boolean;
  regex?: string;
  options?: FieldOption[];
}

export enum FieldType {
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
}

interface FieldOption {
  id: number;
  name: string;
  displayName: string;
  displayNameFrench: string;
  order: number;
}
