import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LinkType } from "../../../constants/types";
import "./MobileNavDropdown.scss";

export interface MobileNavDropdownProps {
  name: string;
  items: MobileNavDropdownItem[];
}

export interface MobileNavDropdownItem {
  name: string;
  link: string;
  type: LinkType;
}

const MobileNavDropdown: FC<MobileNavDropdownProps> = ({ name, items }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const routeChange = (link: string) => {
    navigate(link, { replace: true });
  };

  const navigateExternal = (link: string) => {
    window.open(link, "_blank");
  };

  const listItems = items.map((item: MobileNavDropdownItem, id: number) => {
    return (
      <React.Fragment key={`mobile_dropdown_item_${id}`}>
        {item.type === LinkType.INTERNAL && (
          <li onClick={() => routeChange(item.link)}>
            <Link to={item.link} title={item.name}>
              {item.name}
            </Link>
          </li>
        )}

        {item.type === LinkType.EXTERNAL && (
          <li onClick={() => navigateExternal(item.link)}>{item.name}</li>
        )}
      </React.Fragment>
    );
  });

  return (
    <div className="tds-mobile-navbar-item">
      <a
        href="# "
        title={name}
        className={`tds-mobile-navbar-dropdown-link` + (open ? " active" : "")}
        onClick={handleOpen}
      >
        {name}
        <span
          className={
            `td-icon icon-mobile ` +
            (open ? "td-icon-upCaret" : "td-icon-downCaret")
          }
        ></span>
      </a>
      <ul className={`tds-mobile-dropdown-content` + (open ? " active" : "")}>
        {listItems}
      </ul>
    </div>
  );
};

export default MobileNavDropdown;
