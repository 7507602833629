/*
 * Do not add any more key value pairs here. this file is to be removed.
 */
export const MESSAGE = "required_field_message";
export const NUMBER_NOT_SUPPORTED_MSG = "number.not.supported";
export const INCORRECT_EMAIL_MSG = "incorrect.email.format";
export const ONLY_NUMBER_MSG = "only.number.message";
export const PHONE_NUMBER_INVALID_MESSAGE =
  "user.registration.invalid.phone.number.message";
export const NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED =
  "user.registration.invalid.name.message";
