import axios, { AxiosResponse } from "axios";

export const createHttpClient = () => {
  let instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  //intercept 500-504 errors and redirect to error page
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};

export const createSecureHttpClient = (token: string) => {
  let instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  //intercept 500-504 errors and redirect to error page
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};

const responseInterceptor = (response: AxiosResponse) => {
  return response;
};

const errorInterceptor = (error: any) => {
  if (error.response.status >= 500) {
    window.location.href = "/?global-error=true";
  }

  return Promise.reject(error);
};
