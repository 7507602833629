import { Application, FieldType } from "../../../data/apiData";

export interface ApplicationDto {
  id: string;
  fields: ApplicationFieldDto[];
}

export interface CheckboxFieldType {
  id: number;
  value: boolean;
}

export interface SingleSelectFieldType {
  id: number;
  value?: number;
}

export interface MultiSelectFieldType {
  id: number;
  value: number[];
}

export interface TextFieldType {
  id: number;
  value: string;
}

export type ApplicationFieldDto =
  | CheckboxFieldType
  | SingleSelectFieldType
  | MultiSelectFieldType
  | TextFieldType;

const createFields = (application: Application): ApplicationFieldDto[] => {
  const fields = application.fields?.flatMap((field) => {
    switch (field.type) {
      case FieldType.CHECKBOX:
        return { id: field.id, value: false };
      case FieldType.SINGLE_SELECT:
        return { id: field.id };
      case FieldType.MULTI_SELECT:
        return { id: field.id, value: [] };
      case FieldType.TEXT:
        return {
          id: field.id,
          value: "",
        };
      default:
        return [];
    }
  });
  return fields ? fields : [];
};

export const createAppDto = (application: Application): ApplicationDto => {
  return { id: application.id, fields: createFields(application) };
};

export interface AppAccessRequest {
  apps: ApplicationDto[];
}
