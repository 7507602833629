import { FC } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  COOKIE_CONSENT_COOKIE_EXPIRATION,
  COOKIE_CONSENT_COOKIE_NAME,
} from "../../../constants/constants";
import "./CookieConsentBanner.scss";
import { removeTracking } from "../../../utils/CookieConsentUtil";
import { useAuth } from "../../../auth/AuthProvider";
import { AD_CHOICES, LANDING_AD_CHOICES } from "../../../constants/routePaths";

interface Props {}

const CookieConsentBanner: FC<Props> = () => {
  const { t } = useTranslation();
  let { authenticated } = useAuth();

  const handleAccept = () => {
    window.location.reload();
  };

  const handleDeclineCookie = () => {
    removeTracking();

    window.location.reload();
  };

  return (
    <CookieConsent
      enableDeclineButton
      disableStyles={true}
      containerClasses="cookie-consent"
      contentClasses="consent-content"
      overlayClasses="consent-overlay"
      buttonWrapperClasses="consent-buttons"
      buttonClasses="btn td-btn-primary-light"
      declineButtonClasses="btn td-btn-secondary-clear"
      flipButtons
      location="bottom"
      buttonText={t("cookie.consent.banner.accept")}
      declineButtonText={t("cookie.consent.banner.decline")}
      cookieName={COOKIE_CONSENT_COOKIE_NAME}
      expires={COOKIE_CONSENT_COOKIE_EXPIRATION}
      onAccept={() => handleAccept()}
      onDecline={handleDeclineCookie}
    >
      {t("cookie.consent.banner.text")}{" "}
      <Link
        to={authenticated ? AD_CHOICES : LANDING_AD_CHOICES}
        title={t("cookie.consent.banner.link")}
      >
        {t("cookie.consent.banner.link")}
      </Link>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
