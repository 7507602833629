import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import background from "../../../assets/images/Banner-Image.webp";
import "./LearnMore.scss";
import { homePageContext } from "../../../pages/Home";
import { useAuth } from "../../../auth/AuthProvider";
import ReactGA from "react-ga4";
import { FAQS_ARTICLES, REGISTRATION, ROOT } from "../../../constants/routePaths";
import Faq from "./Faq/Faq";
import HelpArticle from "./Faq/HelpArticle";
import { createHttpClient } from "../../../utils/HttpClient";
import { FaqAndArticleData } from "./Faq/FaqAndArticle";
import { isEnglish } from "../../../utils/Utils";

interface Props {}

const LearnMore: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticated, authenticate } = useAuth();
  const homePageData = useContext(homePageContext);
  const [data, setData] = useState<FaqAndArticleData>();

  const onLoginClick = () => {
    ReactGA.event({
      action: "login_btn_click",
      category: "landing_page",
    });
    authenticate();
  };

  useEffect(() => {
    document.title = t("browser.title.learn.more.page");
  }, [t]); 

  useEffect(() => {
    createHttpClient().get<FaqAndArticleData>(FAQS_ARTICLES)
      .then((resp) => {
        setData(resp.data);
      });
  }, []);

  return (
    <>
      <div className="tds-homepage-container learn-more">
        <div
          className="video-bar"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <div className="tds-homepage-video-content" tabIndex={0}>
            <section>
              {authenticated && (
                <h1>{`${t("home.main.banner.title.hi")} ${
                  homePageData?.userInfo.firstName
                },`}</h1>
              )}
              <h1 aria-label={t("home.main.banner.title.welcome")}>{`${t("home.main.banner.title.welcome")}`}</h1>
              <h1 className="one-portal-banner-title" aria-label={t("home.main.banner.title")}>
                {t("home.main.banner.title")}
              </h1>
              {!authenticated && (
                <button
                  onClick={onLoginClick}
                  className="tds-homepage-video-bar-button btn btn-block td-btn-secondary-clear"
                >
                  {t("login")}
                </button>
              )}
            </section>
          </div>
        </div>

        <div className="tds-container">
          <button className="back-button" onClick={() => navigate(ROOT)} aria-label={t("button.back")}>
            &lt; {t("button.back")}
          </button>
          <div className="tds-divider-space-10">&nbsp;</div>
          <h1 tabIndex={0}>{t("learn.title")}</h1>
          <p tabIndex={0}>{t("learn.desc.1")}</p>
          <p tabIndex={0}>{t("learn.desc.2")}</p>
          <div className="points">
            <ul>
              <li className="learn-more-margin-bottom" tabIndex={0}>
                <span className="font-weight-bold">
                  {t("learn.point.1.header")}
                </span>
                {" - " + t("learn.point.1")}
              </li>
              <li className="learn-more-margin-bottom" tabIndex={0}>
                <span className="font-weight-bold">
                  {t("learn.point.2.header")}
                </span>
                <span>{" - " + t("learn.point.2")}</span>
              </li>
              <li className="learn-more-margin-bottom" tabIndex={0}>
                <span className="font-weight-bold">
                  {t("learn.point.3.header")}
                </span>
                <span>{" - " + t("learn.point.3")}</span>
              </li>
              <li tabIndex={0}>
                <span className="font-weight-bold">
                  {t("learn.point.4.header")}
                </span>
                <span>{" - " + t("learn.point.4")}</span>
              </li>
            </ul>
          </div>
          {!authenticated && (
            <button
              onClick={() => navigate(REGISTRATION)}
              className="btn td-btn-primary-light landing-request-access-btn learn-more-registration-button"
              type="button"
            >
              {t("landing.request-access-box.button")}
            </button>
          )}
          <div className={ `tds-faq-div ${(isEnglish() ? 'margin-top-en' : 'margin-top-fr')}` }>
            { data?.articles && data?.articles?.length > 0 && 
              <div><hr className="hr"/>
                <HelpArticle data={data?.articles}/>
              </div>
            }
            { data?.faqs && data?.faqs?.length > 0 && 
              <div>
                <hr className="hr"/><Faq data={data?.faqs}/>
              </div>
            }
          </div>
        </div>
        <div className="tds-divider-space-10">&nbsp;</div>
      </div>
    </>
  );
};

export default LearnMore;
