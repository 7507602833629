import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../../constants/routePaths";
import HeaderNavUnAuthenticated from "../HeaderNav/HeaderNavUnAuthenticated";
import { CONFIRMATION_MSG } from "./RegistrationRequestConstants";
import { useEffect, useRef } from "react";

const RequestAccessConfirmation = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const confirmationRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    confirmationRef?.current?.focus();
  });
  return (
    <div
      className="tds-landing-request-access-confirmation" tabIndex={0} ref={confirmationRef} 
    >
      <HeaderNavUnAuthenticated/>
      <div className="tds-landing-request-access-confirmation-container">
        <h2 tabIndex={0}>{t("registration.confirmation.title")} </h2>
        <p tabIndex={0}>
        {t(CONFIRMATION_MSG)}
        </p>
        <button
          className="btn td-btn-primary-light"
          onClick={() => navigate(LOGIN)}
        >
          {t("done")}
        </button>
      </div>
    </div>
  );
};

export default RequestAccessConfirmation;
