import * as React from "react";
import { FC, useState } from "react";
import { Field } from "../../../data/apiData";
import CustomCheckbox from "../../Common/customCheckbox/CustomCheckbox";
import { ApplicationDto, MultiSelectFieldType } from "./AppAccessRequestUtils";
import { useTranslation } from "react-i18next";
import { MESSAGE } from "../../Common/ErrorMessage/ErrorMessagesConstants";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage";

interface Props {
  validated: boolean;
  field: Field;
  appId: string;
  appDto: ApplicationDto;
  handleFieldValueChanged: (
    appId: string,
    fieldId: number,
    value: string | boolean | number | number[]
  ) => void;
}

const MultiSelectField: FC<Props> = ({
  validated,
  field,
  appId,
  appDto,
  handleFieldValueChanged,
}) => {
  const { i18n } = useTranslation();
  const [changed, setChanged] = useState<boolean>(false);

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const getFieldDto = (id: number) => {
    return appDto.fields.find((f) => f.id === id);
  };

  const isSelected = (optionId: number) => {
    const fieldDto: MultiSelectFieldType = appDto.fields.find(
      (f) => f.id === field.id
    ) as MultiSelectFieldType;
    return fieldDto.value.includes(optionId);
  };

  const handleOptionClicked = (value: number, selected: boolean) => {
    const fieldDto: MultiSelectFieldType = getFieldDto(
      field.id
    ) as MultiSelectFieldType;
    let newOptionDto = [...fieldDto.value.filter((v) => v !== value)];

    if (selected) {
      newOptionDto = [...fieldDto.value, value];
    }
    handleFieldValueChanged(appId, field.id, newOptionDto);
  };

  const isFieldSet = (fieldId: number) => {
    const value = appDto.fields.find((f) => f.id === fieldId)
      ?.value as number[];
    return value && value.length;
  };

  return (
    <div
      key={"multi_select_id_" + field.id}
      className={`tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding ${
        field.required && "tds-landing-form-required"
      }`}
    >
      <label id={"label_id_" + field.id} htmlFor={"id_" + field.id}>
        {isEnglish() ? field.displayName : field.displayNameFrench}
      </label>
      <div>
        {field.options?.map((o) => {
          return (
            <CustomCheckbox
              style={{ display: "inline-block", marginRight: "30px" }}
              key={"option_id_" + o.id}
              value={o.id}
              labelText={isEnglish() ? o.displayName : o.displayNameFrench}
              selected={isSelected(o.id)}
              handleClicked={handleOptionClicked}
              onBlur={() => setChanged(true)}
            />
          );
        })}
      </div>
      {(changed || validated) && field.required && !isFieldSet(field.id) && (
        <div
          className="tds-landing-request-access-form-error"
          style={{ marginTop: "-10px" }}
        >
          <ErrorMessage
            message={MESSAGE}
            fieldId={isEnglish() ? field.displayName : field.displayNameFrench}
          />
        </div>
      )}
    </div>
  );
};

export default MultiSelectField;
