import React, { FC, useContext, useEffect, useState } from "react";
import "./HeaderNav.scss";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/tds_shield.svg";
import logoFrench from "../../../assets/images/TDS_Header_Logo_FR.svg";

import HeaderNavDropdown, {
  HeaderNavDropdownData,
} from "../HeaderNavDropdown/HeaderNavDropdown";
import { LEARN, ROOT } from "../../../constants/routePaths";
import { Link } from "react-router-dom";
import { homePageContext } from "../../../pages/Home";
import { Application, HomePageCategory } from "../../../data/apiData";
import MobileNav, {
  MobileNavLeft,
  MobileNavProps,
} from "../MobileNav/MobileNav";
import { Language, LinkType } from "../../../constants/types";
import { MobileNavDropdownItem } from "../MobileNavDropdown/MobileNavDropdown";
import { useAuth } from "../../../auth/AuthProvider";

interface Props {}

const HeaderNav: FC<Props> = () => {
  const homePageData = useContext(homePageContext);

  const { i18n, t } = useTranslation();
  /* Language */
  const [language, setLanguage] = useState<Language>(Language.en_CA);

  useEffect(() => {
    setLanguage(
      i18n.language.startsWith("en") ? Language.en_CA : Language.fr_CA
    );
  }, [i18n]);

  /* User dropdown */
  const [username, setUsername] = useState<string>("");

  let { logout, transferToUAPM } = useAuth();

  const onLogoutLinkChange = () => {
    logout();
  };

  const onProfileLinkClick = () => {
    transferToUAPM();
  };

  const userDropDownData: HeaderNavDropdownData = {
    title: t("home.right_menu.user.title"),
    text: username,
    dropdownlinkStyle: "tds-top-nav-dropdown-link",
    contentStyle: "right user",
    openOnHover: false,
    items: [
      {
        text: t("home.right_menu.user.profile"),
        title: t("home.right_menu.user.profile"),
        link: ROOT,
        type: LinkType.INTERNAL,
        onInternalLinkChange: onProfileLinkClick,
      },
      {
        text: t("home.right_menu.user.log_out"),
        title: t("home.right_menu.user.log_out"),
        link: ROOT,
        type: LinkType.INTERNAL,
        onInternalLinkChange: onLogoutLinkChange,
      },
    ],
  };

  useEffect(() => {
    setUsername(
      `${homePageData?.userInfo.firstName} ${homePageData?.userInfo.lastName}`
    );
  }, [homePageData]);

  /* Language dropdown */
  const handleLanguageChange = (language: Language) => {
    i18n.changeLanguage(language);

    setLanguage(language);
  };

  const isEnglish = () => {
    return language.startsWith("en");
  };

  const english = t("home.right_menu.language.english");
  const french = t("home.right_menu.language.french");

  const englishDropDownData: HeaderNavDropdownData = {
    title: t("home.right_menu.language.title"),
    text: isEnglish() ? english : french,
    dropdownlinkStyle: "tds-top-nav-dropdown-link",
    contentStyle: "language",
    openOnHover: false,
    items: [
      {
        text: english,
        title: english,
        link: "#",
        type: LinkType.LANGUAGE,
        withLanguageCheckMark: isEnglish() ? true : false,
        language: Language.en_CA,
        onLanguageChange: handleLanguageChange,
      },
      {
        text: french,
        title: french,
        link: "#",
        type: LinkType.LANGUAGE,
        withLanguageCheckMark: isEnglish() ? false : true,
        language: Language.fr_CA,
        onLanguageChange: handleLanguageChange,
      },
    ],
  };

  /* Mobile Nav data */
  let categoriesDrowdownData: MobileNavLeft[] = [];

  if (homePageData) {
    categoriesDrowdownData = homePageData?.categories.map(
      (item: HomePageCategory, id: number) => {
        const linkItems = item.applications.map((application: Application) => {
          const itemData: MobileNavDropdownItem = {
            name: isEnglish() ? application.name : application.frenchName,
            link: application.url,
            type: LinkType.EXTERNAL,
          };

          return itemData;
        });

        const dropdownData: MobileNavLeft = {
          type: "dropdown",
          dropdownData: {
            name: isEnglish() ? item.name : item.frenchName,
            items: linkItems,
          },
        };
        return dropdownData;
      }
    );
  }

  const mobileNavData: MobileNavProps = {
    leftItems: [
      ...Array.from(categoriesDrowdownData),
      {
        type: "link",
        linkData: {
          name: english,
          link: "/",
          type: LinkType.LANGUAGE,
          withLanguageCheckMark: isEnglish() ? true : false,
          language: Language.en_CA,
          onLanguageChange: handleLanguageChange,
        },
      },
      {
        type: "link",
        linkData: {
          name: french,
          link: "/",
          type: LinkType.LANGUAGE,
          withLanguageCheckMark: isEnglish() ? false : true,
          language: Language.fr_CA,
          onLanguageChange: handleLanguageChange,
        },
      },
    ],
    right: {
      username: username,
      items: [
        {
          name: t("home.right_menu.user.profile"),
          link: ROOT,
          type: LinkType.INTERNAL,
          onInternalLinkChange: onProfileLinkClick,
        },
        {
          name: t("home.right_menu.user.log_out"),
          link: ROOT,
          type: LinkType.INTERNAL,
          onInternalLinkChange: onLogoutLinkChange,
        },
      ],
    },
  };

  return (
    <div>
      <header>
        <div className="header-utility-bar"></div>
        <div className="td-bg-colour-2 tds-header">
          <div className="tds-container">
            <div className="tds-section-left">
              <Link to={ROOT} title={t("td_securities")} className="tds-logo">
                <img
                  src={isEnglish() ? logo : logoFrench}
                  title={t("td_securities")}
                  alt={t("td_securities")}
                />
              </Link>
            </div>
            <div className="tds-section-right">
              <div className="tds-top-nav right">
                <Link to={LEARN} title={t("home.main.banner.button")} aria-label={t("home.main.banner.button")}>
                  {t("home.main.banner.button")}
                </Link>

                <HeaderNavDropdown {...englishDropDownData} />
                <HeaderNavDropdown {...userDropDownData} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileNav {...mobileNavData} />
    </div>
  );
};

export default HeaderNav;
