import { useTranslation } from "react-i18next";
import Collapse from "../../../Common/Collapse/Collapse";
import "./Faq.scss";
import { FC } from "react";
import { ArticleData } from "./FaqAndArticle";
import { isEnglish } from "../../../../utils/Utils";
import { HELP_ARTICLES } from "../../../../constants/routePaths";
import { Link } from "react-router-dom";

interface Props {
  data?: ArticleData[];
}

const HelpArticle: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="tds-sn-faq-container">
      <Collapse
        title={t("learn.more.help.article.title")}
        bottomBorder="none"
        displayType="flex"
        isParentSection={true}
      >
        {data?.map((article: ArticleData, index: number) => (
          <div key={index} className="summary" style={{ borderBottom: "none" }}>
            <div style={{ paddingLeft: "42px" }}>
              {isEnglish() ? article.nameEn : article.nameFr}
              <Link
                to={
                  HELP_ARTICLES +
                  "/" +
                  article.id +
                  "/content" +
                  "?lang=" +
                  `${isEnglish() ? "en" : "fr"}`
                }
                title={isEnglish() ? article.nameEn : article.nameFr}
                style={{ fontWeight: "bold" }}
                target="_blank"
              >
                <span
                  className={"td-icon td-icon-distributionGuide"}
                  style={{ paddingLeft: "5px" }}
                ></span>
              </Link>
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};
export default HelpArticle;
